.admin {
  padding: 10px;
  max-height: 80vh;
  /* background: white; */
  text-align: center;
  margin: auto;
}

.google-container {
  display: flex;
  justify-content: center;
}


.instructions-options {
  display: flex; 
  align-items: center;
  margin-bottom: 10px;
}

.instructions-options p {
  margin-right: 20px;
}

.pointer {
  cursor: pointer;
}

.column-flex-default {
  display: flex;
  flex-direction: column;
}

.display-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

.w-80 {
  width: 80vw;
}

.form-piece {
  border-bottom: 1px solid grey;
}

.form-piece input {
  border: none; 
  background-color: #00000000;
  margin-top: 10px;
}

.form-piece input:focus {
  outline: none;
}

form, form button, input {
  font-family: 'Cormorant Garamond', serif;
}

form  > button {
  background: #00000000;
  border: 1px solid black;
  padding: 5px 10px; 
  margin-top: 20px; 
}

#newProjForm {
  margin-top: 10vh;
  margin-bottom: 10vh;
}

.post-success {
  margin: 0;
}

@media screen and (max-width: 500px) {
  .display-grid {
    grid-template-columns: 1fr;
  }

  .instructions-options {
    flex-direction: column;
    align-items: start;
  }
}

