*, button, a{
  font-family: 'Cormorant Garamond', serif;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
}

body::before {
  content: "";
  background: url('./images/crystals.png');
  opacity: 70%;
  background-repeat: no-repeat;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-size: cover;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.material-symbols-outlined {
  font-variation-settings:
  'FILL' 0,
  'wght' 400,
  'GRAD' 0,
  'opsz' 48
}

.row-flex {
  flex-direction: row;
  justify-content: space-between;
}

.align-self-start {
  align-self: start
}

.column-flex {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.descriptive-section {
  height: 100%;
  width: 90%;
  text-align: center;
  font-family: 'Cormorant Garamond', serif;
}

h1, .heading-link {
  font-family: 'Cormorant Garamond', serif;
  letter-spacing: 0.1rem;
  font-weight: lighter;
}

.page-header {
  font-size: 30px;
}

button {
  color: black;
  cursor: pointer;
}


.contact-btn {
  color: black;
  text-decoration: none;
  margin: 20px 0px;
  padding: 10px;
  background: #F5F0E5;
  font-family: 'Cormorant Garamond', serif;
  font-size: large;
  cursor: pointer;
  min-width: 150px;
}

.hidden {
  display: none;
}

::-webkit-scrollbar {
  width: 10px;

}

::-webkit-scrollbar-track {
  background: #f5f0e5cd;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #eadec5;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #e4d6b7;
} 

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0%;
  }
  100% {
    opacity: 100%;
  }
}

@keyframes fadeInRegular {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 100%;
  }
}