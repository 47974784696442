#aboutSection {
  justify-content: flex-start;  
  height: auto;
  margin: auto;
  padding: 5% 2%;
  max-width: 100vw;
  max-height: 90vh;
  background: white;
  animation: fadeInRegular 1s ease-in;
}

.scroll-about-section {
  overflow-y: auto;
  max-height: 50vh;
}

.scroll-about-section p {
  word-wrap: wrap;
}

.about-pic {
  width: 200px;
  max-width: 90vw;
  border-radius: 50%;
}