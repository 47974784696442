.single-project {
  border-radius: 15px;
  background: rgba(238, 236, 233, 0.53);
  width: 90%;
  max-width: 700px;
  display: flex;
  min-height: fit-content;
  display: flex; 
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  margin: 3%;
  padding: 3% 0%;
  position: relative;
  animation: fadeInRegular 1.5s ease-in;
}

.single-project > a > img, .non-link-img {
  position: relative;
  height: 300px;
  width: 400px;
  max-width: 100%;
  object-fit: cover;
  border-radius: 18px;
  margin: 0% 1%;
}

.single-project > a {
  max-width: 90%;
}

.single-project-section {
  height: auto;
  width: 270px;
  border-radius: 21px;
  display: flex; 
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: space-around;
  margin: 0% 1%;
}

.single-project-section p {
  margin: 10px 0px;
}

.single-project-section img {
  width: 40px;
  height: 40px;
  cursor: pointer;
}

h2 {
  font-weight: lighter;
  margin: 0;
}

.delete-btn {
  background: rgb(228, 220, 184); 
  border-radius: 50%;
  height: 30px;
  width: 30px;
  border: 1px solid black;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 900px) {
  .single-project {
    min-width: fit-content;
    min-height: fit-content;
  }

  .project-img {
    margin: 2% 1%;
  }

  .single-project-section {
    margin: 2% 1%;
    width: 300px
  }
}

@media screen and (max-width: 300px) {
  .brand-logo {
    width: 95vw;
  }

  .single-project > a > img {
    width:80vw;
    height: 180px;
  }

  .single-project-section {
    width: 80vw;
  }
}
