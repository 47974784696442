.nav {
  background-color: #fff;
  border-bottom: 3px solid lightgray;
  height: 70px;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0% 2% 0% 0%;
}

.menu-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.resume-button {
  border: none;
  padding: 5% 8%;
  background: #F5F0E5;
  font-family: 'Cormorant Garamond', serif;
  font-size: large;
  cursor: pointer;
  min-width: 150px;
}

.nav-link.active {
  border-bottom: 1px solid black;
}

.nav-link {
  color: black;
  text-decoration: none;
  text-align: center;
  font-family: 'Cormorant Garamond', serif;
  font-size: 20px;
  border: 1px solid transparent;
  padding: 2px 8px;
  margin: 0% 1%;
  cursor: pointer;
  transition: border-color 0.5s;
}


.nav-buttons {
  width: 40%;
  display: flex; 
  justify-content: space-around;
}

.heading-link img {
  margin-left: 20px; 
  width: 100px;
}

@media screen and (max-width: 1000px) {
  .nav-buttons {
    width: 60%;
  }
}