.contact-details-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.contact-info {
  align-self: start;
}

.contact-info div {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  padding-right: 20px;
}

.contact-info h1, .contact-info p {
  text-align: left;
}

.contact-info p, .contact-info a {
  margin: 8px 0px;
}

.contact-details {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-left: 1px solid black;
  padding-left: 20px;
}

.contact-section {
  width: 100%;
  height: auto;
  padding: 30px 0px;
  background: rgba(255, 255, 255, 0.90);
  margin: auto;
  justify-content: center;
  animation: fadeInRegular 1s ease-in;
}

#widgetContainer {
  max-width: 90vw;
  overflow-x: auto;
  overflow-y: hidden;
}

@media screen and (max-width: 1000px) {
  .contact-details-container {
    flex-direction: column;
  }

  .contact-info {
    border-bottom: 1px solid black;
    width: 80vw;
    display: flex;
    justify-content: space-around;
    padding-bottom: 20px;
  }

  .contact-info div {
    padding-right: 0;
  }

  .contact-info h1, .contact-info p {
    word-wrap: break-word
  }

  .contact-details {
    border-left: none;
    padding-left: 0;
    width: 80vw;
  }
}

@media screen and (max-width: 480px) {
  .contact-info {
    flex-direction: column;
  }
  
  .contact-info h1, .contact-info p {
    text-align: center;
  }

  .contact-info div {
    align-items: center;
  }
}