.menu {
  height: 100%;
  width: 30%;
  background: #FFF;
  border-right: 3px solid lightgray;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.close-button {
  align-self: flex-end;
}

.menu-buttons {
  height: 60%;
  margin: 30% 0%;
  display: flex; 
  flex-direction: column; 
  justify-content: space-between;
}

.page-button {
  font-family: 'Cormorant Garamond', serif;
  font-size: x-large;
  width: 100%;
  border-bottom: 1px solid black;
  margin: 10% 0% 10% 0%;
  padding-bottom: 10%;
}

@media screen and (max-width: 800px) {
  .menu {
    width: 40%
  }
}

@media screen and (max-width: 700px) {
  .menu {
    width: 45%
  }
}

@media screen and (max-width: 625px) {
  .menu {
    width: 100%;
    border-right: none;
  }
}