.project-section {
  width: 90%;
  max-height: 90%;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;
}

.main-project-page {
  display: flex; 
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-height: 90%;
  background: rgba(255, 255, 255, 0.90);
  margin: auto;
  padding-bottom: 10px;
  animation: fadeInRegular 1s ease-in;
}