main {
  height: 100vh;
  overflow-y : auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.app {
  max-height: 100vh;
  overflow: hidden;
  display: flex; 
  flex-direction: column;
}